import React from "react";

const Footer = ({color}) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 2.14272C22 1.27484 21.2964 0.571289 20.4286 0.571289H1.57143C0.703552 0.571289 0 1.27484 0 2.14272C0 3.01059 0.703552 3.71415 1.57143 3.71415H20.4286C21.2964 3.71415 22 3.01059 22 2.14272Z"
        fill={color}
      />
      <path
        d="M22 17.857C22 16.9891 21.2964 16.2856 20.4286 16.2856H1.57143C0.703552 16.2856 0 16.9891 0 17.857C0 18.7249 0.703552 19.4284 1.57143 19.4284H20.4286C21.2964 19.4284 22 18.7249 22 17.857Z"
        fill={color}
      />
      <path
        d="M22 9.99986C22 9.13198 21.2964 8.42843 20.4286 8.42843H9.42857C8.5607 8.42843 7.85714 9.13198 7.85714 9.99986C7.85714 10.8677 8.5607 11.5713 9.42857 11.5713H20.4286C21.2964 11.5713 22 10.8677 22 9.99986Z"
        fill={color}
      />
    </svg>
  );
};

export default Footer;
