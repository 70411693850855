import React from "react";
import { useLocation, Link } from "react-router-dom";
import { Twitter, Discord, Medium, ArrowUp } from "@styled-icons/bootstrap";
import { motion, AnimatePresence } from "framer-motion";
import { OptionDimensions } from "@styled-icons/bootstrap/Option";

const MediumIcon = "https://s3.amazonaws.com/fukushimafish.io/website_assets/medium-icon.svg";
const DiscordIcon = "https://s3.amazonaws.com/fukushimafish.io/website_assets/discord-icon.svg";
const OpenseaIcon = "https://s3.amazonaws.com/fukushimafish.io/website_assets/opensea-icon.svg";


const Footer = () => {
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <motion.footer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      {location.pathname !== "/claim" && (
        <motion.button
          whileHover={{ scale: 1.06 }}
          whileTap={{ scale: 1.2 }}
          id="scroll_up_button"
          onClick={() => scrollToTop()}
        >
          <ArrowUp size="30px" />
        </motion.button>
      )}
      <div className="footer-icons">
        <motion.a
          whileHover={{ scale: 1.06 }}
          whileTap={{ scale: 1.2 }}
          className="single-icon-box"
          href="https://opensea.io/collection/fukushima-fish"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={OpenseaIcon} />
        </motion.a>
        <motion.a
          whileHover={{ scale: 1.06 }}
          whileTap={{ scale: 1.2 }}
          className="single-icon-box"
          href="https://twitter.com/Fukushima_Fish"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Twitter size="25px" />
        </motion.a>
        <motion.a
          whileHover={{ scale: 1.06 }}
          whileTap={{ scale: 1.2 }}
          className="single-icon-box"
          href="mailto:mintcelestialstudios@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={MediumIcon} />
        </motion.a>
        <motion.a
          whileHover={{ scale: 1.06 }}
          whileTap={{ scale: 1.2 }}
          className="single-icon-box"
          href="https://discord.gg/8VW5Hecfqm"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={DiscordIcon} />
        </motion.a>
      </div>
      <div className="footer-info">
        <div className="terms-and-conditions">
          <Link
            to="../termsconditions"
            onClick={() => scrollToTop()}
            className="underline"
          >
            Terms & Conditions
          </Link>
          <Link
            to="../privacypolicy"
            onClick={() => scrollToTop()}
            className="underline"
          >
            Privacy Policy
          </Link>
        </div>
        <span className="align-item-center">
          ©Fukushima Fish 2023 | All Rights Reserved
        </span>
        <span className="align-item-end">
          Designed by{" "}
          <a
            href="https://missynt.myportfolio.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ cursor: "pointer", color: "#fff" }}
          >
            <u>0xMissy</u>
          </a>{" "}
          | Developed by{" "}
          <a
            href="https://twitter.com/BlLcrypto"
            target="_blank"
            rel="noopener noreferrer"
            style={{ cursor: "pointer", color: "#fff" }}
          >
            <u>0xBill</u>
          </a>
        </span>
      </div>
    </motion.footer>
  );
};

export default Footer;
