import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AnimatedRoutes from "./pages/AnimatedRoutes";
import Header from "./components/Header";
import "./App.css";
//import { ethers, BigNumber } from "ethers";
import Web3 from "web3";

const correctChainId = 1;

const App = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [isWrongChain, setIsWrongChain] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [provider, setProvider] = useState(window.ethereum);
  const [chainId, setChainId] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [error, setError] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  const isMobile = width <= 900;
  const is500 = width <= 500;
  const is1440 = width <= 1440;

  useEffect(() => {
    const getAccountsFirstTime = async () => {
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      const chainId = await web3.eth.getChainId();
      if (accounts.length === 0) {
        console.log("Metamask not connected!");
      } else if (accounts[0] !== currentAccount) {
        setProvider(provider);
        setWeb3(web3);
        setChainId(chainId);
        setCurrentAccount(accounts[0]);
        setIsConnected(true);
        if (chainId !== correctChainId) {
          setIsWrongChain(true);
          console.log("Make sure u are connected to ethereum!");
        }
      }
    };
    getAccountsFirstTime();

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        onLogout();
      } else if (accounts[0] !== currentAccount) {
        setCurrentAccount(accounts[0]);
      }
    };

    const handleChainChanged = async () => {
      const web3ChainId = await web3.eth.getChainId();
      if (web3ChainId !== correctChainId) {
        setIsWrongChain(true);
        console.log("Its not ethereum");
      } else {
        setIsWrongChain(false);
      }
      setChainId(web3ChainId);
    };

    if (isConnected) {
      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
    }

    return () => {
      if (isConnected) {
        provider.removeListener("accountsChanged", handleAccountsChanged);
        provider.removeListener("chainChanged", handleChainChanged);
      }
    };
  }, [isConnected]);

  const onLogin = async (provider) => {
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    const chainId = await web3.eth.getChainId();
    if (accounts.length === 0) {
      console.log("Please connect to MetaMask!");
    } else if (accounts[0] !== currentAccount) {
      setProvider(provider);
      setWeb3(web3);
      setChainId(chainId);
      setCurrentAccount(accounts[0]);
      setIsConnected(true);
      if (chainId !== correctChainId) {
        setIsWrongChain(true);
        console.log("Make sure u are connected to ethereum!");
      }
    }
  };

  const onLogout = () => {
    setIsConnected(false);
    setCurrentAccount(null);
  };


  return (
    <Router>
      <Header
        isConnected={isConnected}
        currentAccount={currentAccount}
        onLogin={onLogin}
        onLogout={onLogout}
        isMintPage={false}
        isMobile={isMobile}
        is500={is500}
      />
      <AnimatedRoutes
        isConnected={isConnected}
        currentAccount={currentAccount}
        onLogin={onLogin}
        onLogout={onLogout}
        isWrongChain={isWrongChain}
        isMobile={isMobile}
        is500={is500}
        is1440={is1440}
      />
    </Router>
  );
};

export default App;
