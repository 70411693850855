import React from "react";
import "../terms.css";
import { Link, useLocation } from "react-router-dom";

const TermsConditions = () => {
  return (
    <div className="Terms">
      <div className="terms-container">
        <h2>Terms & Conditions</h2>
        <div>
          <h3>Privacy Policy</h3>
          <p>
            The Fukushima Fish is a collection of digital artworks (NFTs)
            running on the Ethereum network. This website is only an interface
            allowing participants to exchange digital collectibles. Users are
            entirely responsible for the safety and management of their own
            private Ethereum wallets and validating all transactions and
            contracts generated by this website before approval. Furthermore, as
            the Fukushima Fish smart contracts run on the Ethereum network,
            there is no ability to undo, reverse, or restore any transactions.
          </p>
        </div>
        <div>
          <h3>Privacy Policy</h3>
          <p>
            Please review our{" "}
            <Link
              to="../privacypolicy"
              style={{ color: "red", cursor: "pointer" }}
            >
              Privacy Policy
            </Link>
            , which also governs your use of the Fukushima Fish NFTs, for
            information on how we collect, use and share your information.
          </p>
        </div>
        <div>
          <h3>OWNERSHIP</h3>
          <p>
            <b>i.</b> You own the NFT. Each Fukushima Fish is an NFT on the
            Ethereum blockchain. When you purchase an NFT, you own the
            underlying Fukushima Fish completely. Ownership of the NFT is
            mediated entirely by the Smart Contract and the Ethereum Network: at
            no point may we seize, freeze, or otherwise modify the ownership of
            any Fukushima Fish NFT.
            <br />
            <br />
            <b>ii.</b> Personal Use. Subject to your continued compliance with
            these Terms, Celestial Studios LLC grants you a worldwide,
            non-exclusive, royalty-free license to use, copy, and display the
            Art, along with any extensions that you choose to create or use,
            solely for the following purposes: (i) for your own personal,
            non-commercial use; (ii) as part of a marketplace that permits the
            purchase and sale of your Fukushima Fish / NFT, provided that the
            marketplace cryptographically verifies each Fukushima Fish owner’s
            rights to display the Art for their Fukushima Fish NFT to ensure
            that only the actual owner can display the Art; or (iii) as part of
            a third party website or application that permits the inclusion,
            involvement, or participation of your Fukushima Fish, provided that
            the website/application cryptographically verifies each Fukushima
            Fish owner’s rights to display the Art for their Fukushima Fish NFT
            to ensure that only the actual owner can display the Art, and
            provided that the Art is no longer visible once the owner of the
            Fukushima Fish NFT leaves the website/application.
            <br />
            <br />
            <b>iii.</b> Commercial Use. Subject to your continued compliance
            with these Terms, Celestial Studios LLC grants you an unlimited,
            worldwide, non-exclusive license to use, copy, and display the Art
            for the purpose of creating merchandise and media based upon the Art
            (“Commercial Use”). Examples of such Commercial Use would e.g. be
            the use of the Art to produce and sell merchandise products
            (T-Shirts etc.) displaying the Art. You will be entitled to retain
            any revenue that you generate through Commercial Use. You can sell
            its likeness, make derivatives of it or you can give others
            permission to use it. We’ve given licensing rights over for the
            Fukushima Fish NFT’s, but not our logo, names (Fukushima Fish), site
            images, or other marketing material.{" "}
            <b>
              If it isn’t your Fukushima Fish NFT, it isn’t your Intellectual
              Property
            </b>
            . Most of these logos, names, and images related to Fukushima Fish
            have either trademarked pending status or are the intellectual
            property of the artist(s). That said, just because our logo may
            appear somewhere on your Fukushima Fish NFT, that does not give you
            the right to use that logo other than on the Fukushima Fish NFT
            itself or on a derivative of that Fukushima Fish NFT. You can’t take
            the logo and use it to brand an item for sale or use it for
            marketing of a product. We don’t mind personal use or fan art usage
            of our logos, just so long as you don’t try and sell them. Regarding
            the term “Fukushima Fish”. We allow usage of the term Fukushima Fish
            only when used in conjunction with your Fukushima Fish Token ID #.
            For instance, you can’t use Fukushima Fish alone on merch or NFTs,
            but you can use Fukushima Fish#2383, Fukushima Fish#1567 etc. Think
            of it like this: You own your Fukushima Fish NFT, not Fukushima
            Fish, so accurately representing yourself, the brand, and/or
            product, while not infringing on our brand, is important.
            <br />
            <br />
            <b>iv.</b> Marketing and Advertising. Celestial Studios LLC. reserves the right
            to use any Art within The Fukushima Fish series for any reason,
            including but not limited to including the Art as part of The
            Fukushima Fish Marketing and Advertising, without any obligations to
            you. All rights not expressly granted above are reserved by the
            Celestial Studios LLC. For clarity, Celestial Studios LLC will have
            the right to utilize the Art as part of The Fukushima Fish Marketing
            and Advertising to generate revenue and will be solely entitled to
            retain any such revenue.
          </p>
        </div>
        <div>
          <h3>WARRANTY DISCLAIMER</h3>
          <p>
            THE WEBSITE AND ITS CONNECTED SERVICES (THE “SERVICES”), ANY CONTENT
            CONTAINED THEREIN, AND ANY NFTS (INCLUDING ASSOCIATED ART) LISTED
            THEREIN ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT
            WARRANTIES, REPRESENTATIONS, OR GUARANTEES OF ANY KIND, EITHER
            EXPRESS OR IMPLIED. CELESTIAL STUDIOS LLC AND ITS SUPPLIERS MAKE NO
            WARRANTY THAT THE SERVICES OR NFTS: (I) WILL MEET YOUR REQUIREMENTS;
            (II) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR
            ERROR-FREE BASIS; OR (III) WILL BE ACCURATE, RELIABLE, COMPLETE, OR
            SAFE. CELESTIAL STUDIOS LLC. DISCLAIMS ALL OTHER WARRANTIES REPORTS,
            OR GUARANTEES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
            INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, QUIET ENJOYMENT, TITLE OR NON-INFRINGEMENT, TO
            THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AS TO THE SERVICES,
            ANY CONTENT CONTAINED THEREIN AND ANY NFTS LISTED THEREIN.
          </p>
        </div>
        <div>
          <h3>INDEMNITY</h3>
          <p>
            You will indemnify, defend (at Celestial Studios LLC’s option) and
            hold Celestial Studios LLC. and its officers, directors, employees
            and agents, harmless from and against any claims, disputes, demands,
            liabilities, damages, losses, and costs and expenses, including,
            without limitation, reasonable legal and accounting fees arising out
            of or in any way connected with (a) your access to or use of the
            Services, or (b) your violation of these Terms & Conditions. You may
            not settle or otherwise compromise any claim subject to this
            Indemnity Section without Celestial Studios LLC.’s prior written
            approval.
          </p>
        </div>
        <div>
          <h3>LIMITATION OF LIABILITY</h3>
          <p>
            <b>i.</b> TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER CELESTIAL
            STUDIOS LLC. NOR ITS SERVICE PROVIDERS INVOLVED IN CREATING,
            PRODUCING, OR DELIVERING THE SERVICES WILL BE LIABLE FOR ANY
            INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES
            FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS
            OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION,
            COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES
            OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS &
            CONDITIONS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR
            NFTS, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
            NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND
            WHETHER OR NOT CELESTIAL STUDIOS LLC OR ITS SERVICE PROVIDERS HAVE
            BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED
            REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
            PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION
            OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE
            LIMITATION MAY NOT APPLY TO YOU.
            <br />
            <br />
            <b>ii.</b> TO THEMAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL
            THE AGGREGATE TOTAL LIABILITY OF CELESTIAL STUDIOS LLC AND ITS
            AGENTS, REPRESENTATIVES, AND AFFILIATES ARISING OUT OF OR IN
            CONNECTION WITH THESE TERMS & CONDITIONS OR FROM THE USE OF OR
            INABILITY TO USE THE SERVICES OR NFTS EXCEED THE AMOUNTS YOU HAVE
            PAID OR ARE PAYABLE BY YOU TO CELESTIAL STUDIOS LLC FOR USE OF THE
            SERVICES OR ONE HUNDRED U.S. DOLLARS ($100), IF YOU HAVE NOT HAD ANY
            PAYMENT OBLIGATIONS TO CELESTIAL STUDIOS LLC, AS APPLICABLE.
            <br />
            <br />
            <b>iii.</b> THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH
            ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN
            CELESTIAL STUDIOS LLC AND YOU.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
