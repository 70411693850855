import React from "react";
import "../terms.css";
import { Link, useLocation } from "react-router-dom";

const TermsConditions = () => {
  return (
    <div className="Terms">
      <div className="terms-container">
        <h2>Privacy Policy</h2>
        <div>
          <p>
            This Privacy Policy is designed to help you understand how Celestial
            Studios LLC (“ Celestial Studios LLC,” “we,” “us ,” and “ our ”)
            collects, uses, and shares your personal information on The
            Fukushima Fish website located at https://fukushimafish.io/ (the “
            Site”) and to help you understand and exercise your privacy rights.
          </p>
          <div className="policy">
            <div className="policy-container">
              <div>
                <h4>1. SCOPE</h4>
                <p>
                  This Privacy Policy applies to personal information processed
                  by Celestial Studios LLC on the Site.
                </p>
              </div>
              <div>
                <h4>2. CHANGES TO OUR PRIVACY POLICY</h4>
                <p>
                  We may revise this Privacy Policy from time to time in our
                  sole discretion. If there are any material changes to this
                  Privacy Policy, we will notify you as required by applicable
                  law. You understand and agree that you will be deemed to have
                  accepted the updated Privacy Policy if you continue to use our
                  Site after the new Privacy Policy takes effect.
                </p>
              </div>
              <div>
                <h4>3. PERSONAL INFORMATION WE COLLECT</h4>
                <p>
                  The categories of personal information we collect depend on
                  how you interact with us, our Site, and the requirements of
                  applicable law. We collect information that you provide to us,
                  information we obtain automatically when you use our Site, and
                  information from other sources such as third-party services
                  and organizations, as described below.
                </p>
              </div>
              <div>
                <h4>4. HOW WE USE YOUR INFORMATION</h4>
                <p>
                  We use your information for a variety of business purposes,
                  including to provide our Site, for administrative purposes and
                  to market our products and services, as described below.
                </p>
                <div>
                  <div>
                    <h5>A. Provide our Site</h5>
                    <p>
                      We use your information to fulfill our contract with you
                      and provide you with our Site, including but not limited
                      to:
                    </p>
                    <ul>
                      <li>Managing your information;</li>
                      <li>
                        Providing access to certain areas, functionalities, and
                        features of our Site;
                      </li>
                      <li>
                        Communicating with you about your activities on our Site
                        and policy changes;
                      </li>
                      <li>
                        Processing your digital wallet information to facilitate
                        transactions via the Site; and
                      </li>
                      <li>Allowing you to register for events</li>
                    </ul>
                  </div>
                  <div>
                    <h5>B. Administrative Purposes</h5>
                    <p>
                      We use your information for various administrative
                      purposes, including but not limited to:
                    </p>
                    <ul>
                      <li>
                        Pursuing our legitimate interests such as research and
                        development (including marketing research), network and
                        information security, and fraud prevention;
                      </li>
                      <li>
                        Detecting security incidents, protecting against
                        malicious, deceptive, fraudulent or illegal activity,
                        and prosecuting those responsible for that activity;
                      </li>
                      <li>Measuring interest and engagement in our Site;</li>
                      <li>Improving, upgrading or enhancing our Site;</li>
                      <li>Developing new products and Site;</li>
                      <li>Ensuring internal quality control and safety;</li>
                      <li>
                        Authenticating and verifying individual identities;
                      </li>
                      <li>
                        Debugging to identify and repair errors with our Site;
                      </li>
                      <li>
                        Auditing relating to interactions, transactions and
                        other compliance activities;
                      </li>
                      <li>Enforcing our agreements and policies; and</li>
                      <li>Complying with our legal obligations.</li>
                    </ul>
                  </div>
                  <div>
                    <h5>C. Marketing our Products and Services</h5>
                    <p>
                      We may use personal information to tailor and provide you
                      with content. We may provide you with these materials as
                      permitted by applicable law.
                      <br />
                      <br />
                      If you have any questions about our marketing practices or
                      if you would like to opt out of the use of your personal
                      information for marketing purposes, you may contact us at
                      any time as set forth in Section 14 below.
                    </p>
                  </div>
                  <div>
                    <h5>D. Other Purposes</h5>
                    <p>
                      We also use your information for other purposes as
                      requested by you or as permitted by applicable law.
                    </p>
                    <ul>
                      <li>
                        <b>Consent .</b>We may use personal information for
                        other purposes that are clearly disclosed to you at the
                        time you provide personal information or with your
                        consent.
                      </li>
                      <li>
                        <b>De-identified and Aggregated Information.</b>We may
                        use personal information and other information about you
                        to create de-identified and/or aggregated information,
                        such as de-identified demographic information,
                        de-identified location information, information about
                        the device from which you access our Site, or other
                        analyses we create.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                <h4>5. HOW WE DISCLOSE YOUR INFORMATION</h4>
                <p>
                  We disclose your information to third parties for a variety of
                  business purposes, including to provide our Site, to protect
                  us or others, or in the event of a major business transaction
                  such as a merger, sale, or asset transfer, as described below.
                </p>
                <div>
                  <div>
                    <h5>A. Disclosures to Provide our Site</h5>
                    <p>
                      The categories of third parties with whom we may share
                      your information are described below.
                    </p>
                    <ul>
                      <li>
                        <b>Notice Regarding Use of Blockchain.</b>If you elect
                        to sell, purchase, or trade any Fukushima Fish NFTs, any
                        financial transactions that you engage in will be
                        conducted solely through the Blockchain network
                        governing such NFT and/or on the NFT marketplace
                        platform (such as GigaMart or Byt) where you will trade
                        such NFTs. Information about your transactions will be
                        provided to the applicable Blockchain and may be
                        accessible to third parties due to the nature of the
                        Blockchain.
                      </li>
                      <li>
                        <b>Third Party Websites and Applications.</b>You may
                        choose to share personal information or interact with
                        third-party websites and/or third-party applications
                        which may reference or link to our Site, including, but
                        not limited to, third-party electronic wallet extensions
                        or NFT marketplaces (e.g., GigaMart, SwapStreet, BLUR,
                        OpenSea). Once your personal information has been shared
                        with a third-party website or a third-party application,
                        it will also be subject to such third party’s privacy
                        policy. We encourage you to closely read each
                        third-party website or third-party application privacy
                        policy before sharing your personal information or
                        otherwise interacting with them. Please note that we do
                        not control and we are not responsible for the
                        third-party website’s or the third-party application’s
                        processing of your personal information, or the privacy
                        practices or content of such other websites or
                        applications. Providing personal information to
                        third-party websites or applications is at your own
                        risk.
                      </li>
                      <li>
                        <b>Service Providers .</b>We may share your personal
                        information with our third-party service providers who
                        use that information to help us provide our Site. This
                        includes service providers that provide us with IT
                        support, hosting, payment processing, customer service,
                        and related services.
                      </li>
                      <li>
                        <b>Business Partners .</b>
                        We may share your personal information with business
                        partners to provide you with a product or service you
                        have requested. We may also share your personal
                        information with business partners with whom we jointly
                        offer products or services.
                      </li>
                      <li>
                        <b>Affiliates .</b>We may share your personal
                        information with members of our corporate family and our
                        affiliates.
                      </li>
                      <li>
                        <b>APIs/SDKs .</b>We may use third-party application
                        program interfaces (“ APIs ”) and software development
                        kits (“ SDKs ”) as part of the functionality of our
                        Site. For more information about our use of APIs and
                        SDKs, please contact us as set forth in Section 14
                        below.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h5>B. Disclosures to Protect Us or Others</h5>
                    <p>
                      We may access, preserve, and disclose any information we
                      store associated with you to external parties if we, in
                      good faith, believe doing so is required or appropriate
                      to: (i) comply with law enforcement or national security
                      requests and legal process, such as a court order or
                      subpoena; (ii) protect your, our, or others’ rights,
                      property, or safety; (iii) enforce our policies or
                      contracts; (iv) collect amounts owed to us; or (v) assist
                      with an investigation or prosecution of suspected or
                      actual illegal activity.
                    </p>
                  </div>
                  <div>
                    <h5>
                      C. Disclosure in the Event of Merger, Sale, or Other Asset
                      Transfers
                    </h5>
                    <p>
                      If we are involved in a merger, acquisition, financing due
                      diligence, reorganization, bankruptcy, receivership,
                      purchase or sale of assets, or transition of service to
                      another provider, your information may be sold or
                      transferred as part of such a transaction, as permitted by
                      law and/or contract.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <h4>6. YOUR PRIVACY CHOICES AND RIGHTS</h4>
                <div>
                  <div>
                    <p>
                      <b>Your Privacy Choices .</b> The privacy choices you may
                      have about your personal information are determined by
                      applicable law and are described below.
                    </p>
                    <ul>
                      <li>
                        <b>“Do Not Track.”</b>Do Not Track (“DNT ”) is a privacy
                        preference that users can set in certain web browsers.
                        Please note that we do not respond to or honor DNT
                        signals or similar mechanisms transmitted by web
                        browsers.
                      </li>
                      <li>
                        <b>Cookies .</b>You may stop or restrict the placement
                        of Technologies on your device or remove them by
                        adjusting your preferences as your browser or device
                        permits. However, if you adjust your preferences, our
                        Site may not work properly. <br />
                        <br />
                        Please note you must separately opt out in each browser
                        and on each device.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p>
                      <b>Your Privacy Rights .</b> In accordance with applicable
                      law, you may have the right to:
                    </p>
                    <ul>
                      <li>
                        <b>
                          Access Personal Information about you, including: (i)
                          confirming whether we are processing your personal
                          information; (ii) obtaining access to or a copy of
                          your personal information; or (iii) receiving an
                          electronic copy of personal information that you have
                          provided to us, or asking us to send that information
                          to another company (aka the right of data
                          portability);
                        </b>
                      </li>
                      <li>
                        <b>
                          Request Correction of your personal information where
                          it is inaccurate or incomplete. In some cases, we may
                          provide self-service tools that enable you to update
                          your personal information;
                        </b>
                      </li>
                      <li>
                        <b>Request Deletion of your personal information;</b>
                      </li>
                      <li>
                        <b>
                          Request Restriction of or Object to our processing of
                          your personal information; and
                        </b>
                      </li>
                      <li>
                        <b>
                          Withdraw Your Consent to our processing of your
                          personal information.
                        </b>
                      </li>
                    </ul>
                  </div>
                </div>
                <p>
                  If you would like to exercise any of these rights, please
                  contact us as set forth in Section 14 below. We will process
                  such requests in accordance with applicable laws.
                </p>
              </div>
              <div>
                <h4>7. SECURITY OF YOUR INFORMATION</h4>
                <p>
                  {" "}
                  <b>
                    We take steps designed to ensure that your information is
                    treated securely and in accordance with this Privacy Policy.
                  </b>{" "}
                  Unfortunately, no system is 100% secure, and we cannot ensure
                  or warrant the security of any information you provide to us.
                  To the fullest extent permitted by applicable law, we do not
                  accept liability for unauthorized disclosure. By using our
                  Site or providing personal information to us, you agree that
                  we may communicate with you electronically regarding security,
                  privacy, and administrative issues relating to your use of our
                  Site. If we learn of a security system’s breach, we may
                  attempt to notify you electronically by posting a notice on
                  our Site, by mail, or by sending an email to you.{" "}
                </p>
              </div>
              <div>
                <h4>8. INTERNATIONAL DATA TRANSFERS</h4>
                <p>
                  All information processed by us may be transferred, processed,
                  and stored anywhere in the world, including, but not limited
                  to, the United States or other countries, which may have data
                  protection laws that are different from the laws where you
                  live. We endeavor to safeguard your information consistent
                  with the requirements of applicable laws.
                </p>
              </div>
              <div>
                <h4>9. RETENTION OF PERSONAL INFORMATION</h4>
                <p>
                  We store the personal information we collect as described in
                  this Privacy Policy for as long as you use our Site or as
                  necessary to fulfill the purpose(s) for which it was
                  collected, provide our Site, resolve disputes, establish legal
                  defenses, conduct audits, pursue legitimate business purposes,
                  enforce our agreements, and comply with applicable laws.
                </p>
              </div>
              <div>
                <h4>10. SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS</h4>
                <p>
                  This Supplemental California Privacy Policy only applies to
                  our processing of personal information that is subject to the{" "}
                  <b>California Consumer Privacy Act of 2018 (“ CCPA ”).</b> The
                  CCPA provides California residents with the right to know what
                  categories of personal information Celestial Studios LLC has
                  collected about them, and whether Celestial Studios LLC
                  disclosed that personal information for a business purpose
                  (e.g., to a service provider) in the preceding twelve months.
                  California residents can find this information below:
                </p>
                <p>
                  <img src="https://s3.amazonaws.com/fukushimafish.io/website_assets/policy.png" />
                </p>
                <p>
                  The categories of sources from which we collect personal
                  information and our business and commercial purposes for using
                  personal information are set forth above.
                </p>
                <br />
                <br />
                <p>Additional Privacy Rights for California Residents</p>
                <br />
                <br />
                <p>
                  “Sales” of Personal Information under the CCPA. For purposes
                  of the CCPA, Celestial Studios LLC does not “sell” personal
                  information, nor do we have actual knowledge of any “sale” of
                  personal information of minors under 16 years of age.
                </p>
                <br />
                <br />
                <p>
                  <b>Non-Discrimination.</b>California residents have the right
                  not to receive discriminatory treatment by us for the exercise
                  of their rights conferred by the CCPA.
                </p>
                <p>
                  <b>Authorized Agent.</b>Only you, or someone legally
                  authorized to act on your behalf, may make a verifiable
                  consumer request related to your personal information. To
                  designate an authorized agent, please contact us as set forth
                  in Section 14 below.
                </p>
                <p>
                  <b>Verification.</b>When you make a request, we will ask you
                  to provide sufficient information that allows us to reasonably
                  verify you are the person about whom we collected personal
                  information or an authorized representative, which may include
                  confirming the email address associated with any personal
                  information we have about you.
                </p>
                <p>
                  If you are a California resident and would like to exercise
                  any of your rights under the CCPA, please contact us as set
                  forth in Section 14 below. We will process such requests in
                  accordance with applicable laws.
                </p>
              </div>
              <div>
                <h4>11. SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS</h4>
                <p>
                  If you are a resident of Nevada, you have the right to opt-out
                  of the sale of certain personal information to third parties
                  who intend to license or sell that personal information. You
                  can exercise this right by contacting us as set forth in
                  Section 14 below with the subject line “Nevada Do Not Sell
                  Request” and providing us with your name and the email address
                  associated with your account. Please note that we do not
                  currently sell your personal information as sales are defined
                  in Nevada Revised Statutes Chapter 603A.
                </p>
              </div>
              <div>
                <h4>12. CHILDREN’S INFORMATION</h4>
                <p>
                  The Site is not directed to children under 13 (or other age as
                  required by local law), and we do not knowingly collect
                  personal information from children. If you learn that your
                  child has provided us with personal information without your
                  consent, you may contact us as set forth in Section 14 below.
                  If we learn that we have collected a child’s personal
                  information in violation of applicable law, we will promptly
                  take steps to delete such information.
                </p>
              </div>
              <div>
                <h4>13. SUPERVISORY AUTHORITY</h4>
                <p>
                  If you are located in the European Economic Area, Switzerland,
                  or the United Kingdom you have the right to lodge a complaint
                  with a supervisory authority if you believe our processing of
                  your personal information violates applicable law.
                </p>
              </div>
              <div>
                <h4>14. CONTACT US</h4>
                <p>
                  If you have any questions about our privacy practices or this
                  Privacy Policy, or to exercise your rights as detailed in this
                  Privacy Policy, please contact us via our{" "}
                  <a
                    href="https://discord.gg/8VW5Hecfqm"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    Discord
                  </a>{" "}
                  channel or at <b>celestialstudios@gmail.com</b>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
