import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import Footer from "../components/Footer";
import "../App.css";
import "../about.css";

/*Images */
const koi01 =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/about_page_koi01.png";
const koi02 =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/about_page_koi02.png";
const koi03 =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/about_page_koi03.png";
const plant01 =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/about_plant01.png";
const plant02 =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/about_plant02.png";

const About = ({ isMobile, is500, is1440 }) => {
  const ref = useRef(null);
  const scrollToCenter = () => {
    if (isMobile) {
      ref.current.scrollTo({
        left: ref.current.offsetWidth / 2 + (!is500 ? 50 : -32),
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToCenter();
  }, [isMobile, is500]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1.2 }}
    >
      <img src={koi01} id="koi01" />

      <div className="About">
        <section className="first-section">
          <div className="first-section-container">
            <div className="first-section-wrapper">
              <h2>Meet The Team</h2>
              <div>
                <div className="team-presentation" ref={ref}>
                  <div className="team-presentation-container">
                    <div className="team-card">
                      <div className="team-card-overlay"></div>
                      <div className="team-bubble">
                        <div id="ben"></div>
                      </div>
                      <div className="team-info">
                        <p>Ben Humbert</p>
                        <p>Co-founder</p>
                        <p>
                          Meet Ben - a visionary leader behind Fukushima Fish.
                          He has a passion for innovative technology, and
                          quality behind each project he delivers.
                        </p>
                      </div>
                    </div>
                    <div className="team-card">
                      <div className="team-card-overlay"></div>
                      <div className="team-bubble">
                        <div id="atomik"></div>
                      </div>
                      <div className="team-info">
                        <p>AtomikSushi</p>
                        <p>Co-founder</p>
                        <p>
                          AtomikSushi is a co-founder of the Fukushima Fish NFT
                          collection, working alongside Neo_Glyph and Ben to
                          create a beautiful and moving tribute to the
                          resilience of both Koi and Humankind. Their
                          collaboration has produced a stunning collection of
                          NFTs that captivates and inspires
                        </p>
                      </div>
                    </div>
                    <div className="team-card">
                      <div className="team-card-overlay"></div>
                      <div className="team-bubble">
                        <div id="neo"></div>
                      </div>
                      <div className="team-info">
                        <p>Neo_Glyph</p>
                        <p>Co-founder & Artist</p>
                        <p>
                          Neo_Glyph is the talented artist behind the Fukushima
                          Fish Collection. As an artist, Neo_Glyph is fascinated
                          by the intersection of technology, nature, and human
                          society, and this is reflected in the intricate
                          details and vivid colors of his work.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="second-section">
          <div className="second-section-container">
            <div className="second-section-wrapper">
              <img src={koi02} id="koi02" />
              <div>
                <h2>Powered by Celestial Studios</h2>
                <p>
                  Celestial Studios offers unique, high-quality art that you can
                  truly call your own. Using web3 tech, each piece's
                  intellectual property is securely transferred to its owner,
                  giving you the freedom to sell reproductions, license it for
                  commercial use, or even register it for copyright/trademark
                  protection.
                </p>
                <p>
                  Our talented artists push the boundaries of creativity and
                  craftsmanship, offering a wide range of styles and mediums,
                  from digital paintings to charcoal drawings. Whether a
                  seasoned collector or beginner, we invite you to browse our
                  collection and discover the beauty and potential of our web3
                  art.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="third-section">
          <div id="koi03">
            <img src={koi03} />
          </div>
          <div className="third-section-container">
            <div className="third-section-wrapper">
              <div className="third-section-box-one">
                <h2>Our Partners</h2>
                <p>
                  Partnerships of Fukushima Fish allow holders additional{" "}
                  <br style={{ display: isMobile && "none" }} />
                  paths for utilities of their IP and artwork
                </p>
              </div>
              <div className="third-section-box-two">
                <div className="partnership-list">
                  <div className="partnership-list-container animate">
                    <a
                      href="https://nftcanvases.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a
                      href="https://www.usecyan.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a className="partnership-card"></a>
                    <a
                      href="https://birthmark-beta.vercel.app/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a className="partnership-card"></a>
                    <a
                      href="https://www.swapstreet.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a
                      href="https://www.protocol.style/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>

                    <a
                      href="https://nftcanvases.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a
                      href="https://www.usecyan.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a className="partnership-card"></a>
                    <a
                      href="https://birthmark-beta.vercel.app/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a className="partnership-card"></a>
                    <a
                      href="https://www.swapstreet.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a
                      href="https://www.protocol.style/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <img src={plant01} id="about_plant01" />
          <img src={plant02} id="about_plant02" />
        </section>
      </div>
    </motion.div>
  );
};

export default About;
