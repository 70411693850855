import React, { useState, useEffect } from "react";
import { ethers, BigNumber } from "ethers";
import { motion, AnimatePresence } from "framer-motion";
import Footer from "../components/Footer";
import Login from "../components/Login";
import "../mint.css";
import "../App.css";
import { X, Plus, Dash, ArrowLeft } from "@styled-icons/bootstrap";
import Modal from "react-modal";
import Abi from "../config/abi.json";
import FishDataAbi from "../config/fishDataAbi.json";
import SupplyControllerAbi from "../config/supplyControllerAbi.json";
import { Alchemy, Network } from "alchemy-sdk";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { saveAs } from "file-saver";
import LoadingIcon from "../images/loading.gif";

//react toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const config = {
  apiKey: process.env.REACT_APP_ALCHEMY_KEY,
  network: Network.ETH_MAINNET,
};
const alchemy = new Alchemy(config);

/*images*/
const EthLogo =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/eth.png";
const MintButton =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/mint_button.png";
/*const bgImage =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/mint_background.png";*/
const bgVideo =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/background_video.mp4";

const koiImages = () => {
  let arr = [];
  for (let index = 1; index <= 4; index++) {
    arr.push(
      `https://s3.amazonaws.com/fukushimafish.io/website_assets/mint_koi${index}.png`
    );
  }
  return arr;
};

/* mint info */

//MAINNET
const contractAddress = "0xEe259D1aae364bCb2D28333c712d394F052984c3";
const auraAddress = "0x8C4b835BfB8aFED829374fB62Bc8598E324d7C86";
const supplyControllerAddress = "0xcF7b18F2cD2a93AC9c4555797686C956795fa2E0";
const fishDataAddress = "0x6071c02BF8F2775d30cA6D94e1D87FC45542e1c1";

//TESTNET
/*
const contractAddress = "0x5667B16275eFc836B5e3298409cc9c949fA38970";
const auraAddress = "0xbfd52d67b81cFCD37BA36c7AAaB3BC8a40A1993D";
const supplyControllerAddress = "0x6FFaaf15c7bFE095eeE7FB89C3137caee2CB2d6b";
const fishDataAddress = "0x5a0e1A239f0c624E700a238CE3E6F356b836ce60";
*/
const maxAuraSupply = 5000000;
const maxSupply = 3888;
const maxPerWallet = 20;
const defaultErrorMessage = "Hmm.. Something Went Wrong. Lets Try Again";

const getAddressNfts = async (address) => {
  // Get all NFTs
  const connorsNfts = await alchemy.nft.getNftsForOwner(address, {
    contractAddresses: [contractAddress],
    pageSize: 100,
  });
  const nfts = connorsNfts.ownedNfts;
  // Print NFTs
  console.log(nfts);
  return nfts;
};

const modalStyle = {
  overlay: {
    background: "rgba(4, 7, 33, 0.5)",
    backdropFilter: " blur(7.5px)",
    zIndex: "99",
    /* Note: backdrop-filter has minimal browser support */
  },
  content: {
    color: "#FCEBBC",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    width: "471px",
    backgroundColor: "rgba(255, 255, 255, 0)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "0",
    padding: "0",
    overflowX: "hidden",
  },
};

const handleDragStart = (e) => e.preventDefault();

/*
interface AddrInfo {
  address: String,
  whitelistSlots:Number,
  ownedTokens:Number,
  hasKoi:Boolean,
  path: Number,
  proof: Array
}*/

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const handleDownload = (url, nftId) => {
  if (url.includes("https://")) {
    saveAs(
      `https://ipfs.io/ipfs/QmYERydgGGxakAJbc7fzLxuJwXRowWkdMgG3j8wRspRSXB/${nftId}.png`,
      `${nftId}.png`,
      false
    );
    alert("Download has started");
  }
};

const Mint = ({
  isConnected,
  currentAccount,
  onLogin,
  onLogout,
  isWrongChain,
  isMobile,
}) => {
  let tokenClaimData = require("../config/fukushima_fish_token_data.json");

  const [isOpen, setIsOpen] = useState(false);
  const [currentAddressEthBalance, setCurrentAddressEthBalance] = useState(0);
  const [mintAmount, setMintAmount] = useState(1);
  const [publicMintPrice, setPublicMintPrice] = useState(0);
  const [allowlistMintPrice, setAllowlistMintPrice] = useState(0);
  const [totalMintedSoFar, setTotalMintedSoFar] = useState(0);
  const [errorMintPage, setErrorMintPage] = useState(false);
  const [successMintPage, setSuccessMintPage] = useState(false);
  const [currentAddressPath, setCurrentAddressPath] = useState(0);
  const [currentAddressProof, setCurrentAddressProof] = useState([]);
  const [amountAllocations, setAmountAllocations] = useState(0);
  const [whitelistMinted, setWhitelistMinted] = useState(0);
  const [mintStatus, setMintStatus] = useState(0);
  const [isMinting, setIsMinting] = useState(false);
  const [isTxPending, setIsTxPending] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

  const [ownedNfts, setOwnedNfts] = useState([]);

  const [isConnectOpen, setIsConnectOpen] = useState(false); //Connect Modal
  const [currentKoi, setCurrentKoi] = useState(0);

  useEffect(() => {
    if (!isMobile) {
      let index = 0;
      const interval = setInterval(() => {
        if (index >= 0 && index <= 3) {
          setCurrentKoi(index++);
        } else if (index === 4) {
          index = 0;
          setCurrentKoi(0);
        }
      }, 2000);
      return () => clearInterval(interval);
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    if (!isWrongChain && isConnected) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, Abi, provider);
      const supplyControllerContract = new ethers.Contract(
        supplyControllerAddress,
        SupplyControllerAbi,
        provider
      );
      const fishDataContract = new ethers.Contract(
        fishDataAddress,
        FishDataAbi,
        provider
      );

      /*const currentAddressWhitelistInfo = tokenClaimData.data.find(
        (i) => i.address.toLowerCase() === currentAccount.toLowerCase()
      );
      //check if address if whitelisted
      if (currentAddressWhitelistInfo) {
        setAmountAllocations(
          Number(currentAddressWhitelistInfo.whitelistSlots)
        );
        setCurrentAddressPath(Number(currentAddressWhitelistInfo.path));
        setCurrentAddressProof(currentAddressWhitelistInfo.proof);
      } else {
        setAmountAllocations(0);
        setCurrentAddressPath(0);
        setCurrentAddressProof([]);
      }*/

      const getTotalMintedSoFarFunction = async () => {
        try {
          const response = await contract.totalSupply();
          setTotalMintedSoFar(Number(response));
        } catch (error) {
          console.log(error);
        }
      };

      const checkMintStatus = async () => {
        try {
          const response = await supplyControllerContract.claimingOpen();
          setMintStatus(Number(response));
        } catch (error) {
          console.log(error);
        }
      };

      /*const getPublicMintPrice = async () => {
        try {
          const response = await contract.PUBLIC_MINT_COST();
          setPublicMintPrice(Number(ethers.utils.formatEther(response)));
        } catch (error) {
          console.log(error);
        }
      };

      const getWhitelistMintPrice = async () => {
        try {
          const response = await contract.WHITELIST_MINT_COST();
          setAllowlistMintPrice(Number(ethers.utils.formatEther(response)));
        } catch (error) {
          console.log(error);
        }
      };

      const getWhitelistMinted = async () => {
        try {
          const response = await contract.minted(currentAccount);
          setWhitelistMinted(Number(response));
        } catch (error) {
          console.log(error);
        }
      };*/

      const getCurrentAddressBalance = async () => {
        try {
          const response = await provider.getBalance(currentAccount);
          setCurrentAddressEthBalance(
            Number(ethers.utils.formatEther(response))
          );
        } catch (error) {
          console.log(error);
        }
      };

      const getCurrentAddressNfts = async () => {
        try {
          const nfts = await getAddressNfts(currentAccount);
          if (nfts.length === 0) {
            setOwnedNfts([]);
          } else {
            nfts.map((i) => {
              i.isTokenInitiated = false;
              i.isLoading = true;
              i.claimableTokens = "0.0";
              return i;
            });
            setOwnedNfts(nfts);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getCurrentAddressBalance();
      checkMintStatus();
      getCurrentAddressNfts();
      //getPublicMintPrice();
      //getWhitelistMintPrice();
      //getTotalMintedSoFarFunction();
      //getWhitelistMinted();

      /*provider.on("block", () => {
        // Emitted on every block change
        getTotalMintedSoFarFunction();
      });*/
    }
  }, [isWrongChain, currentAccount]);

  useEffect(() => {
    if (!isWrongChain && isConnected && ownedNfts.length > 0) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, Abi, provider);
      const supplyControllerContract = new ethers.Contract(
        supplyControllerAddress,
        SupplyControllerAbi,
        provider
      );
      const fishDataContract = new ethers.Contract(
        fishDataAddress,
        FishDataAbi,
        provider
      );

      const newOwnedNfts = ownedNfts;

      const checkIfTokenIniciated = async () => {
        try {
          for (const [index, nft] of ownedNfts.entries()) {
            const response = await fishDataContract.isTokenInitiated(
              nft.tokenId
            );
            newOwnedNfts[index].isTokenInitiated = response;
            if (response) {
              const claimableTokens =
                await supplyControllerContract.getClaimableTokensID(
                  nft.tokenId
                );
              newOwnedNfts[index].claimableTokens =
                ethers.utils.formatEther(claimableTokens);
            }
            newOwnedNfts[index].isLoading = false;
          }
        } catch (error) {
          console.log(error);
        }
      };

      checkIfTokenIniciated();
    }
  }, [ownedNfts]);

  const handleMint = async (minStatus) => {
    if (!isWrongChain) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, Abi, signer);

      if (minStatus === 1) {
        if (
          mintAmount <= 0 ||
          mintAmount > amountAllocations - whitelistMinted
        ) {
          return;
        }

        if (currentAddressEthBalance < allowlistMintPrice * mintAmount) {
          setErrorMessage("Not Enough Funds");
          setErrorMintPage(true);
          return;
        }

        const options = {
          value: ethers.utils.parseEther(
            (Number(allowlistMintPrice) * mintAmount).toString()
          ),
        };

        try {
          setIsMinting(true);
          const mint = await contract.whitelistMint(
            mintAmount,
            amountAllocations,
            currentAddressProof,
            currentAddressPath,
            options
          );
          setIsTxPending(true);
          const response = await mint.wait(1);
          setIsTxPending(false);
          if (response.status) {
            setSuccessMintPage(true);
            setIsMinting(false);
            if (whitelistMinted === 0) {
              setWhitelistMinted(mintAmount);
            } else {
              setWhitelistMinted(Number(whitelistMinted) + Number(mintAmount));
            }
          } else {
            setErrorMintPage(true);
            setErrorMessage(defaultErrorMessage);
          }
        } catch (error) {
          setIsMinting(false);
          setErrorMessage(
            capitalizeFirstLetter(
              error.code.toString().toLowerCase().replace("_", " ")
            )
          );
          setErrorMintPage(true);
        }
        return;
      }

      if (minStatus === 2) {
        // mint is open for the public but wl can still mint at their price

        if (mintAmount <= 0 || mintAmount > maxPerWallet) {
          return;
        }

        if (amountAllocations - whitelistMinted > 0) {
          if (currentAddressEthBalance < allowlistMintPrice * mintAmount) {
            setErrorMessage("Not Enough Funds");
            setErrorMintPage(true);
            return;
          }

          const options = {
            value: ethers.utils.parseEther(
              (Number(allowlistMintPrice) * mintAmount).toString()
            ),
          };

          try {
            setIsMinting(true);
            const mint = await contract.whitelistMint(
              mintAmount,
              amountAllocations,
              currentAddressProof,
              currentAddressPath,
              options
            );
            setIsTxPending(true);
            const response = await mint.wait(1);
            setIsTxPending(false);
            if (response.status) {
              setSuccessMintPage(true);
              setIsMinting(false);
              if (whitelistMinted === 0) {
                setWhitelistMinted(mintAmount);
              } else {
                setWhitelistMinted(
                  Number(whitelistMinted) + Number(mintAmount)
                );
              }
            } else {
              setErrorMintPage(true);
              setErrorMessage(defaultErrorMessage);
            }
          } catch (error) {
            setIsMinting(false);
            setErrorMessage(
              capitalizeFirstLetter(
                error.code.toString().toLowerCase().replace("_", " ")
              )
            );
            setErrorMintPage(true);
          }
          return;
        }

        if (currentAddressEthBalance < publicMintPrice * mintAmount) {
          setErrorMessage("Not Enough Funds");
          setErrorMintPage(true);
          return;
        }

        const options = {
          value: ethers.utils.parseEther(
            (Number(publicMintPrice) * mintAmount).toString()
          ),
        };

        try {
          setIsMinting(true);
          //setMintError("");
          const mint = await contract.publicMint(mintAmount, options);
          setIsTxPending(true);
          const response = await mint.wait(1);
          setIsTxPending(false);
          if (response.status) {
            setSuccessMintPage(true);
            setIsMinting(false);
          } else {
            setErrorMintPage(true);
            setErrorMessage(defaultErrorMessage);
          }
        } catch (error) {
          setIsMinting(false);
          setErrorMessage(
            capitalizeFirstLetter(
              error.code.toString().toLowerCase().replace("_", " ")
            )
          );
          setErrorMintPage(true);
        }
        return;
      }
    } else {
      console.log("wrong chain");
    }
  };

  const handleOpenModal = () => {
    document.body.style.overflow = "hidden";
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    document.body.style.overflow = "auto";
    setIsOpen(false);
    setErrorMintPage(false);
    setSuccessMintPage(false);
    setMintAmount(1);
  };

  const handleOpenConnect = () => {
    setIsConnectOpen(true);
  };

  const handleCloseConnect = () => {
    setIsConnectOpen(false);
  };

  const onAmountChange = (type, maxAmount) => {
    let newAmount = mintAmount;
    if (type === 0) {
      newAmount = newAmount - 1;
    }
    if (type === 1) {
      newAmount = newAmount + 1;
    }
    if (newAmount <= 0 || newAmount > maxAmount) {
      //setMintError("Wrong Amount");
    } else {
      //setMintError("");
      setMintAmount(newAmount);
    }
  };

  const onClickBack = () => {
    setErrorMintPage(false);
    setErrorMessage(defaultErrorMessage);
  };

  const onSwitchNetwork = async () => {
    //const web3 = new Web3(provider);
    try {
      setIsSwitching(true);
      const response = await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0x1",
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
    setIsSwitching(false);
  };

  const handleTokenInitiation = async (nft) => {
    if (!isWrongChain && isConnected) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        supplyControllerAddress,
        SupplyControllerAbi,
        signer
      );
      const currentIdData = tokenClaimData.data.find(
        (i) => i.tokenId.toString() === nft.tokenId
      );

      if (ownedNfts.length <= 0) {
        toast.error("No NFTs");
        return false;
      }

      if (!nft) {
        toast.error("NFT doens't exist");
        return false;
      }

      if (nft.isTokenInitiated) {
        toast.error("Already Initiated");
        return false;
      }

      if (!currentIdData) {
        toast.error("Already Initiated");
        return false;
      }

      try {
        let response = await contract.initializeToken(
          currentIdData.encodedData,
          currentIdData.path,
          currentIdData.proof
        );
        response = await response.wait(1);
        if (response.status) {
          toast.success("Token Initiated!");
          return true;
        } else {
          toast.error("Failed");
          return false;
        }
      } catch (error) {
        toast.error("Metamask Error");
        console.log(error);
        return false;
      }
    }

    return false;
  };

  const handleClaimTokens = async (nft) => {
    if (!isWrongChain && isConnected) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        supplyControllerAddress,
        SupplyControllerAbi,
        signer
      );

      if (ownedNfts.length <= 0) {
        toast.error("No NFTs");
        return false;
      }

      if (!nft) {
        toast.error("NFT doens't exist");
        return false;
      }

      if (!nft.isTokenInitiated) {
        toast.error("Token Was Not Initiated");
        return false;
      }
      
      if (nft.claimableTokens === "0.0") {
        toast.error("Already Claimed");
        return false;
      }

      try {
        let response = await contract.claim(nft.tokenId);
        response = await response.wait(1);
        if (response.status) {
          toast.success("AURA Claimed");
          return true;
        } else {
          toast.error("Failed");
          return false;
        }
      } catch (error) {
        toast.error("Metamask Error");
        console.log(error);
        return false;
      }
    }

    return false;
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <motion.div
        className="container text-center"
        initial={{ opacity: 0.5 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2 }}
        /*initial={{opacity: 0, y: -1200, x: '100%' }}
      animate={{opacity: 1, y: -38, x: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 10 }}*/
      >
        <div className="Mint">
          <video className="videoTag" autoPlay loop muted id="bgVideo">
            <source src={bgVideo} type="video/mp4" />
          </video>
          <div className="Mint-container">
            <section className="mint-section">
              <div className="claim-page">
                {mintStatus === 0 ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 2,
                    }}
                    className="not-live"
                  >
                    <div className="not-live-container">
                      <div>
                        <h4>
                          {isConnected && !isWrongChain
                            ? mintStatus === 0
                              ? "AURA CLAIM IS NOT LIVE YET!"
                              : "AURA CLAIM IS LIVE!"
                            : isWrongChain ? "PLEASE CONNECT TO ETHEREUM" : "CONNECT YOUR WALLET"}
                        </h4>
                      </div>

                      <div className="mint-button-container">
                        {!isConnected && (
                          <motion.button
                            whileHover={{ scale: 1.06 }}
                            whileTap={{ scale: 1.2 }}
                          >
                            <img src={MintButton} />
                            <a
                              onClick={() => {
                                handleOpenConnect();
                              }}
                              className="mint-button-text"
                            >
                              {"CONNECT"}
                            </a>
                            <Login
                              onLogin={onLogin}
                              onLogout={onLogout}
                              image={MintButton}
                              isOpen={isConnectOpen}
                              handleCloseModal={handleCloseConnect}
                            />
                          </motion.button>
                        )}
                      </div>
                    </div>
                  </motion.div>
                ) : !isConnected ? isWrongChain ?     <div className="not-live-container">
                <div>
                  <h4>WRONG CHAIN</h4>
                </div>
              </div> : (
                  <div className="not-live-container">
                    <div>
                      <h4>CONNECT YOUR WALLET</h4>
                    </div>

                    <div className="mint-button-container">
                      {!isConnected && (
                        <motion.button
                          whileHover={{ scale: 1.06 }}
                          whileTap={{ scale: 1.2 }}
                        >
                          <img src={MintButton} />
                          <a
                            onClick={() => {
                              handleOpenConnect();
                            }}
                            className="mint-button-text"
                          >
                            {"CONNECT"}
                          </a>
                          <Login
                            onLogin={onLogin}
                            onLogout={onLogout}
                            image={MintButton}
                            isOpen={isConnectOpen}
                            handleCloseModal={handleCloseConnect}
                          />
                        </motion.button>
                      )}
                    </div>
                  </div>
                ) : ownedNfts.length > 0 ? (
                  <>
                    <div className="nfts-slide">
                      <AliceCarousel
                        disableDotsControls={true}
                        mouseTracking
                        items={ownedNfts.map((i) => (
                          <div
                            className="single-nft-container"
                            onDragStart={handleDragStart}
                            role="presentation"
                          >
                            <div className="single-nft-image">
                              <img src={i.media[0].gateway} />
                            </div>
                            <div className="single-nft-info">
                              <p>{i.title}</p>
                            </div>
                            <div className="single-nft-buttons">
                              {(!i.isTokenInitiated || i.claimableTokens !== "0.0") && (
                                <button
                                  className="claim-button"
                                  onClick={async () => {
                                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                                    const supplyControllerContract = new ethers.Contract(
                                      supplyControllerAddress,
                                      SupplyControllerAbi,
                                      provider
                                    );
                                    if (i.isTokenInitiated) {
                                      i.isLoading = true;
                                      const response = await handleClaimTokens(
                                        i
                                      );
                                      if (response) {
                                        i.claimableTokens = "0.0"
                                      }
                                      i.isLoading = false;
                                    } else {
                                      i.isLoading = true;
                                      const response =
                                        await handleTokenInitiation(i);
                                      i.isTokenInitiated = response;
                                      if (response) {
                                        const claimableTokens =
                                          await supplyControllerContract.getClaimableTokensID(
                                            i.tokenId
                                          );
                                          i.claimableTokens =
                                          ethers.utils.formatEther(claimableTokens);
                                      }
                                      i.isLoading = false;
                                    }
                                  }}
                                >
                                  {i.isLoading ? (
                                    <img
                                      style={{ maxHeight: "13.33px" }}
                                      src={LoadingIcon}
                                    />
                                  ) : i.isTokenInitiated ? (
                                    i.claimableTokens === "0.0" ? (
                                      "CLAIMED"
                                    ) : (
                                      `CLAIM ${i.claimableTokens} AURA`
                                    )
                                  ) : (
                                    "INIT AURA"
                                  )}
                                </button>
                              )}
                              <button
                                className="claim-button"  onClick={() => handleDownload(i.media[0].gateway, i.tokenId)}
                              >
                                DOWNLOAD
                              </button>
                            </div>
                          </div>
                        ))}
                        controlsStrategy="responsive"
                        responsive={{
                          0: {
                            items: 1,
                            itemsFit: "contain",
                          },
                          500: {
                            items: 2,
                            itemsFit: "contain",
                          },
                          720: {
                            items: 3,
                            itemsFit: "contain",
                          },
                          1440: {
                            items: 6,
                            itemsFit: "contain",
                          },
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className="not-live-container">
                    <div>
                      <h4>You don't own any nft</h4>
                    </div>

                    <div className="mint-button-container">
                      <motion.button
                        whileHover={{ scale: 1.06 }}
                        whileTap={{ scale: 1.2 }}
                      >
                        <img src={MintButton} />
                        <a
                          className="mint-button-text"
                          href="https://opensea.io/collection/fukushima-fish"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BUY
                        </a>
                      </motion.button>
                    </div>
                  </div>
                )}
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </motion.div>
    </>
  );
};

/*
<div className="Mint">
        <video className="videoTag" autoPlay loop muted id="bgVideo">
          <source src={bgVideo} type="video/mp4" />
        </video>
        <div className="Mint-container">
          <section className="mint-section">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ y: [500, 0], opacity: 1 }}
              transition={{
                duration: 1.5,
              }}
              className="box-one"
            >
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 1,
                }}
                src={koiImages()[currentKoi]}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ y: [500, 0], opacity: 1 }}
              transition={{
                duration: 2,
              }}
              className="box-two"
            >
              <div className="box-two-info-container">
                <div className="box-two-title-box">
                  {isConnected && !isWrongChain && (
                    <p>
                      {mintStatus === 0
                        ? "AURA CLAIM IS NOT LIVE YET!"
                        : "AURA CLAIM IS LIVE!"}
                    </p>
                  )}

                  <h3 className="box-two-margin-bottom">
                    From the Depths of The <br /> Deep Lake
                  </h3>
                </div>

                <p className=" mint-description box-two-margin-bottom-p fw400">
                  The floating island’s nuclear reactor continue deep into the
                  waters, spilling strange neon liquid mix into its serene body.
                  But, something miraculous is happening. The inhabitants are
                  evolving...
                </p>

                <div className="mint-info box-two-margin-bottom">
                  <div className="box-two-contract-info">
                    <div className="box-two-contract-info-container">
                      <p>50 AURA</p>
                      <p style={{ fontSize: "14px", fontWeight: "300" }}>
                        Claimable
                      </p>
                    </div>
                  </div>
                  <div className="box-two-contract-info">
                    <div className="box-two-contract-info-container">
                      <div className="flex-row-center">
                        <img src={EthLogo} />
                        <p>
                          0 <l style={{ fontWeight: "200" }}>ETH</l>
                        </p>
                      </div>
                      <p style={{ fontSize: "14px", fontWeight: "300" }}>
                        + Gas fee
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mint-button-container">
                  {!isConnected ? (
                    <motion.button
                      whileHover={{ scale: 1.06 }}
                      whileTap={{ scale: 1.2 }}
                    >
                      <img src={MintButton} />
                      <a
                        onClick={() => {
                          handleOpenConnect();
                        }}
                        className="mint-button-text"
                      >
                        {"CONNECT"}
                      </a>
                      <Login
                        onLogin={onLogin}
                        onLogout={onLogout}
                        image={MintButton}
                        isOpen={isConnectOpen}
                        handleCloseModal={handleCloseConnect}
                      />
                    </motion.button>
                  ) : (
                    <motion.button
                      whileHover={{ scale: 1.06 }}
                      whileTap={{ scale: 1.2 }}
                      onClick={handleOpenModal}
                    >
                      <img src={MintButton} />
                      <a className="mint-button-text">CLAIM AURA</a>
                    </motion.button>
                  )}
                </div>
              </div>
            </motion.div>
            <AnimatePresence>
              <Modal
                key={isOpen}
                isOpen={isOpen}
                onRequestClose={!isTxPending ? handleCloseModal : false}
                style={modalStyle}
              >
                {!isTxPending && (
                  <motion.button
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 1.5 }}
                    onClick={handleCloseModal}
                    style={{ zIndex: "99" }}
                  >
                    <X size="40px" style={{ color: "#FCEBBC" }} />
                  </motion.button>
                )}
                <motion.div
                  initial={{ opacity: 0, y: "100vh" }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: "100vh" }}
                  transition={{
                    duration: 0.6,
                  }}
                >
                  {isWrongChain ? (
                    <motion.div
                      key={isWrongChain}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="bubble-container-error"
                    >
                      <div
                        className={
                          !isSwitching
                            ? "container-bg-image"
                            : "container-bg-image animate-container"
                        }
                      ></div>
                      <div className="bubble-container-wrapper-error">
                        <h3>
                          Wrong Network, please connect to Ethereum Mainnet
                        </h3>
                      </div>
                    </motion.div>
                  ) : errorMintPage ? (
                    <motion.div
                      key={errorMintPage}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="bubble-container-error"
                    >
                      <div className="container-bg-image"></div>
                      <div className="bubble-container-wrapper-error">
                        <h3>{errorMessage}</h3>
                      </div>
                    </motion.div>
                  ) : successMintPage ? (
                    <motion.div
                      key={successMintPage}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="bubble-container"
                    >
                      <div className="bubble-container-wrapper-success">
                        <h3>
                          Congrats!
                          <br /> You’ve Successfully Claimed Your Aura Tokens
                        </h3>
                      </div>
                    </motion.div>
                  ) : isTxPending ? (
                    <div className="bubble-container-error">
                      <div className="container-bg-image animate-container"></div>
                      <div className="bubble-container-wrapper-error">
                        <h3>Tx Pending...</h3>
                      </div>
                    </div>
                  ) : mintStatus === 0 ? (
                    <div className="bubble-container-error">
                      <div className="container-bg-image"></div>
                      <div className="bubble-container-wrapper-error">
                        <h3>Aura claim is not open yet</h3>
                      </div>
                    </div>
                  ) : mintStatus === 1 && (
                    ownedNfts.length > 0 ? (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="bubble-container"
                      >
                        <div className="bubble-container-wrapper">
                          <h3>
                            You’ve got {amountAllocations - whitelistMinted}{" "}
                            Fukushima Fish Allocations!
                          </h3>
                          <>
                            <div>
                              <img src={EthLogo} />
                              <p>
                                {(allowlistMintPrice * mintAmount).toFixed(3)}{" "}
                                <e>ETH</e>
                              </p>
                            </div>
                            <div>
                              <motion.div
                                whileHover={{ scale: 1.06 }}
                                whileTap={{ scale: 1.2 }}
                                className="bubble-two-container"
                              >
                                <Dash
                                  size="45px"
                                  onClick={() =>
                                    onAmountChange(
                                      0,
                                      amountAllocations - whitelistMinted
                                    )
                                  }
                                />
                              </motion.div>
                              <div className="amount-box">
                                <h3>{mintAmount}</h3>
                              </div>
                              <motion.div
                                whileHover={{ scale: 1.06 }}
                                whileTap={{ scale: 1.2 }}
                                className="bubble-two-container"
                              >
                                <Plus
                                  size="45px"
                                  onClick={() =>
                                    onAmountChange(
                                      1,
                                      amountAllocations - whitelistMinted
                                    )
                                  }
                                />
                              </motion.div>
                            </div>
                          </>
                        </div>
                      </motion.div>
                    ) : (
                      <>
                        <div className="bubble-container-error">
                          <div className="container-bg-image"></div>
                          <div className="bubble-container-wrapper-error">
                            <h3>
                              You don't have NFTs
                            </h3>
                          </div>
                        </div>
                      </>
                    )
                  )}
                  {mintStatus === 1
                    ? !isWrongChain &&
                      !isMinting &&
                      !successMintPage &&
                      !errorMintPage &&
                      amountAllocations - whitelistMinted > 0 && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                          className="mint-button-container"
                          style={{ bottom: "0" }}
                        >
                          <p
                            className={`text-align-center box-two-margin-bottom-p ${
                              isMobile ? "maxw70" : "maxw90"
                            } `}
                          >
                            By clicking 'Mint' you agree to all{" "}
                            <a
                              href="../termsconditions"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="underline white"
                            >
                              Terms & Conditions
                            </a>{" "}
                            and{" "}
                            <a
                              href="../termsconditions"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="underline white"
                            >
                              Policy
                            </a>{" "}
                            of Fukushima Fish, and agree to the immediate
                            provision of the contractual service (i.e.,
                            receiving the NFT) and you have been informed that
                            you hereby lose the right of{" "}
                            <a
                              href="https://europa.eu/youreurope/citizens/consumers/shopping/guarantees-returns/index_en.htm"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="underline white"
                            >
                              withdrawl
                            </a>
                          </p>

                          <motion.button
                            whileHover={{ scale: 1.06 }}
                            whileTap={{ scale: 1.2 }}
                            onClick={() => handleMint(mintStatus)}
                            style={{ position: "relative", left: "-6px" }}
                          >
                            <img src={MintButton} />
                            <a className="mint-button-text">MINT</a>
                          </motion.button>
                        </motion.div>
                      )
                    : !isWrongChain &&
                      !isMinting &&
                      !successMintPage &&
                      !errorMintPage &&
                      mintStatus !== 0 && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                          className="mint-button-container"
                          style={{ bottom: "0" }}
                        >
                          <p
                            className={`text-align-center box-two-margin-bottom-p ${
                              isMobile ? "maxw70" : "maxw90"
                            } `}
                          >
                            By clicking 'Mint' you agree to all{" "}
                            <a
                              href="../termsconditions"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="underline white"
                            >
                              Terms & Conditions
                            </a>{" "}
                            and{" "}
                            <a
                              href="../termsconditions"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="underline white"
                            >
                              Policy
                            </a>{" "}
                            of Fukushima Fish, and agree to the immediate
                            provision of the contractual service (i.e.,
                            receiving the NFT) and you have been informed that
                            you hereby lose the right of{" "}
                            <a
                              href="https://europa.eu/youreurope/citizens/consumers/shopping/guarantees-returns/index_en.htm"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="underline white"
                            >
                              withdrawl
                            </a>
                          </p>

                          <motion.button
                            whileHover={{ scale: 1.06 }}
                            whileTap={{ scale: 1.2 }}
                            onClick={() => handleMint(mintStatus)}
                            style={{ position: "relative", left: "-6px" }}
                          >
                            <img src={MintButton} />
                            <a className="mint-button-text">MINT</a>
                          </motion.button>
                        </motion.div>
                      )}

                  {errorMintPage && mintStatus !== 0 && (
                    <motion.div
                      className="mint-button-container"
                      style={{ bottom: "0" }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <motion.button
                        whileHover={{ scale: 1.06 }}
                        whileTap={{ scale: 1.2 }}
                        onClick={onClickBack}
                      >
                        <div className="bubble-two-container">
                          <ArrowLeft size="40px" style={{ color: "#FCEBBC" }} />
                        </div>
                      </motion.button>
                    </motion.div>
                  )}

                  {isWrongChain && !isSwitching && (
                    <motion.div
                      className="mint-button-container"
                      style={{ bottom: "0" }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <motion.button
                        whileHover={{ scale: 1.06 }}
                        whileTap={{ scale: 1.2 }}
                        onClick={() => {
                          onSwitchNetwork();
                        }}
                        style={{ position: "relative", left: "-6px" }}
                      >
                        <img src={MintButton} />
                        <a className="mint-button-text">CHANGE NETWORK</a>
                      </motion.button>
                    </motion.div>
                  )}
                </motion.div>
              </Modal>
            </AnimatePresence>
          </section>
          <Footer />
        </div>
      </div>
*/

export default Mint;
