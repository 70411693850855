import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Login from "./Login";
import Menu from "./MenuIcon";

import { X } from "@styled-icons/bootstrap";
import { motion, AnimatePresence } from "framer-motion";

const Logo =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/logo_horizontal.png";
const DarkLogo =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/logo_horizontal02.png";
const LogoMobile =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/logo_mobile.png";

const Header = ({
  isConnected,
  currentAccount,
  onLogin,
  onLogout,
  isMobile,
  is500,
}) => {
  const [menuMobile, setMenuMobile] = useState(false);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false); //Connect Modal

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const onClickMobileIcon = () => {
    if (!menuMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    setMenuMobile(!menuMobile);
  };

  return (
    <header>
      <div className="menu-desktop">
        <div className="logo">
          <Link
            to="/"
            onClick={() => (document.body.style.backgroundImage = "none")}
          >
            <img
              src={
                isMobile
                  ? LogoMobile
                  : location.pathname === "/claim"
                  ? Logo
                  : DarkLogo
              }
              className="logo-image"
              alt="logo"
            />
          </Link>
        </div>
        <div
          className={
            location.pathname === "/claim" ? "menu-list" : "menu-list-dark"
          }
        >
          <Link
            style={{ backgroundColor: location.pathname === "/" && "#FFF0C9" }}
            to="/"
            onClick={() => (document.body.style.backgroundImage = "none")}
          >
            <span>HOME</span>
          </Link>
          <Link
            style={{
              backgroundColor: location.pathname === "/about" && "#FFF0C9",
            }}
            to="/about"
            onClick={() => (document.body.style.backgroundImage = "none")}
          >
            <span>ABOUT</span>
          </Link>
          <a
            href="https://discord.gg/8VW5Hecfqm"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>DISCORD</span>
          </a>
          {location.pathname === "/claim" ? (
            !isConnected ? (
              <>
                <a
                  onClick={() => {
                    handleOpenModal();
                  }}
                >
                  {"CONNECT"}
                </a>
                <Login
                  onLogin={onLogin}
                  onLogout={onLogout}
                  isOpen={isOpen}
                  handleCloseModal={handleCloseModal}
                />
              </>
            ) : (
              <a onClick={onLogout}>{`${currentAccount.slice(
                0,
                2
              )}...${currentAccount.slice(-3)}`}</a>
            )
          ) : (
            <Link
              to="/claim"
              onClick={() => (document.body.style.backgroundImage = "none")}
            >
              <span>CLAIM</span>
            </Link>
          )}
        </div>
      </div>
      <div className="menu-mobile">
        <motion.div
          whileHover={{ scale: 1.06 }}
          whileTap={{ scale: 1.2 }}
          className="logo"
        >
          <Link to="/">
            <img src={LogoMobile} className="logo-image" alt="logo" />
          </Link>
        </motion.div>
        <div className="menu-mobile-icon" onClick={() => onClickMobileIcon()}>
          <motion.div whileHover={{ scale: 1.06 }} whileTap={{ scale: 1.2 }}>
            {!menuMobile ? (
              <motion.div
                whileHover={{ scale: 1.06 }}
                whileTap={{ scale: 1.2 }}
              >
                <Menu
                  color={location.pathname === "/claim" ? "#FCEBBC" : "#000"}
                />
              </motion.div>
            ) : (
              <motion.div
                whileHover={{ scale: 1.06 }}
                whileTap={{ scale: 1.2 }}
              >
                <X
                  size="40px"
                  color={location.pathname === "/claim" ? "#FCEBBC" : "#000"}
                />
              </motion.div>
            )}
          </motion.div>
        </div>
        <AnimatePresence>
          <motion.div
            key={menuMobile}
            initial={{ opacity: 0, y: "50vh" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "50vh" }}
            transition={{ duration: 0.5 }}
            className={
              menuMobile ? "menu-mobile-list active" : "menu-mobile-list"
            }
          >
            <Link
              style={{
                backgroundColor: location.pathname === "/" && "#FFF0C9",
              }}
              to="/"
              onClick={() => (document.body.style.backgroundImage = "none")}
            >
              <span>HOME</span>
            </Link>
            <Link
              style={{
                backgroundColor: location.pathname === "/about" && "#FFF0C9",
              }}
              to="/about"
              onClick={() => (document.body.style.backgroundImage = "none")}
            >
              <span>ABOUT</span>
            </Link>
            <a
              href="https://discord.gg/8VW5Hecfqm"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>DISCORD</span>
            </a>

            {location.pathname === "/claim" ? (
              !isConnected ? (
                <a
                  onClick={() => {
                    handleOpenModal();
                    setMenuMobile(!menuMobile);
                    document.body.style.overflow = "auto";
                  }}
                >
                  {"CONNECT"}
                </a>
              ) : (
                <a onClick={onLogout}>{`${currentAccount.slice(
                  0,
                  4
                )}...${currentAccount.slice(-4)}`}</a>
              )
            ) : (
              <Link
                to="/claim"
                onClick={() => (document.body.style.backgroundImage = "none")}
              >
                <span>CLAIM</span>
              </Link>
            )}
          </motion.div>
        </AnimatePresence>
        <Login
          onLogin={onLogin}
          onLogout={onLogout}
          onClickConnect={() => {
            setMenuMobile(!menuMobile);
            document.body.style.overflow = "auto";
          }}
          isMobile={true}
          isOpen={isOpen}
          handleCloseModal={handleCloseModal}
        />
      </div>
    </header>
  );
};

export default Header;
