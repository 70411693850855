import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { motion, AnimatePresence } from "framer-motion";
import "../App.css";

const modalStyle = {
  overlay: {
    background: "rgba(13, 18, 33, 0.5)",
    zIndex: "20",
    zIndex: "99",
    /* Note: backdrop-filter has minimal browser support */
  },
  content: {
    color: "#000000",
    position: "absolute",
    top: "43%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0",
    padding: "0",
    background: "none",
    overflow: "visible",
  },
};

const modalWrapperStyle = {
  width: "309px",
  height: "fit-content",
  padding: "16px 18px",
  margin: "0",
  borderRadius: "16px",
  backgroundColor: "#E2C47F",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "0",
};

const Login = (props) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [provider, setProvider] = useState(window.provider);
  const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(false);

  useEffect(() => {
    setProvider(detectProvider());
  }, []);

  useEffect(() => {
    if (provider) {
      if (provider !== window.provider) {
        console.error(
          "Not window.ethereum provider.  Do you have multiple wallets installed ?"
        );
      }
      setIsMetaMaskInstalled(true);
    }
  }, [provider]);

  const detectProvider = () => {
    let provider;
    if (window.provider) {
      provider = window.provider;
    } else if (window.web3) {
      provider = window.web3.currentProvider;
    } else {
      console.warn("No Ethereum browser detected! Check out MetaMask");
    }
    return provider;
  };

  const onLoginHandler = async () => {
    setIsConnecting(true);
    await provider.request({
      method: "eth_requestAccounts",
    });
    setIsConnecting(false);
    props.onLogin(provider);
    props.handleCloseModal()
  };

  return (
    <>
      <AnimatePresence>
        <Modal
          key={props.isOpen}
          isOpen={props.isOpen}
          onRequestClose={props.handleCloseModal}
          style={modalStyle}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0}}
            transition={{ duration: 0.5 }}
            style={modalWrapperStyle}
          >
            <p
              style={{
                fontWeight: "800",
                alignSelf: "flex-start",
                marginBottom: "8px",
                padding: "0 8px",
              }}
            >
              CHOOSE WALLET
            </p>
            <div
              className="wallet-type-box"
              onClick={
                isMetaMaskInstalled
                  ? onLoginHandler
                  : () =>
                      window.open(
                        "https://metamask.io/",
                        "_blank",
                        "noopener noreferrer"
                      )
              }
            >
              <span>METAMASK</span>
            </div>
          </motion.div>
        </Modal>
      </AnimatePresence>
    </>
  );
};

export default Login;
