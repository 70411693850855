import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Home from "./Home";
import About from "./About";
import Claim from "./Claim";
import TermsConditions from "./TermsConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import { AnimatePresence } from "framer-motion";

function AnimatedRoutes({
  isConnected,
  currentAccount,
  onLogin,
  onLogout,
  isWrongChain,
  isMobile,
  is500,
  is1440,
}) {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes key={location.pathname} location={location}>
        <Route
          path="/"
          element={
            <Home
              isConnected={isConnected}
              currentAccount={currentAccount}
              onLogin={onLogin}
              onLogout={onLogout}
              isWrongChain={isWrongChain}
              isMobile={isMobile}
              is500={is500}
              is1440={is1440}
            />
          }
        />
        <Route
          path="/about"
          element={
            <About
              isConnected={isConnected}
              currentAccount={currentAccount}
              onLogin={onLogin}
              onLogout={onLogout}
              isWrongChain={isWrongChain}
              isMobile={isMobile}
              is500={is500}
              is1440={is1440}
            />
          }
        />
        <Route
          path="/claim"
          element={
            <Claim
              isConnected={isConnected}
              currentAccount={currentAccount}
              onLogin={onLogin}
              onLogout={onLogout}
              isWrongChain={isWrongChain}
              isMobile={isMobile}
            />
          }
        />
        <Route path="/termsconditions" element={<TermsConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
