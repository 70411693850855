import React, { useRef, useState, useEffect } from "react";
import {
  motion,
  useTransform,
  useScroll,
  useSpring,
  AnimatePresence,
} from "framer-motion";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import "../App.css";
import { ArrowLeft, ArrowRight } from "@styled-icons/bootstrap";

/*images*/
const button01 =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/button01.png";
const button02 =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/button02.png";

const button01Mobile =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/button01_mobile.png";
const button02Mobile =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/button02_mobile.png";
const mintButtonMobile =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/home_mint_button_mobile.png";
const heroContainer900 =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/hero_container_900_test03.png";
const heroContainer1440 =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/hero_container_1440.png";
const mountains1440 =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/hero_mountains_1440.png";
const bgKoi =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/about_page_koi03.png";
const mountains =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/hero_mountains.png";
const heroContainer =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/hero_container.png";
const mintButton =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/home_mint_button.png";
const waterfall =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/home_waterfall.png";
const section1Plant =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section1_plant.png";
const section1Mask =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section1_mask.png";
const smallBubble =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/home_bubble_small.png";
const bubble =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/home_bubble.png";
const section2Kamon =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section2_kamon.png";
const section2Kanji =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section2_kanji.png";
const section2Kanji02 =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section2_kanji02.png";
const section3Tshirt =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section3_tshirt.png";
const section3Skateboard =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section3_skateboard.png";
const section3Iphone =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section3_iphone.png";
const section3Shoes =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section3_shoes.png";
const plant01 =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/about_plant01.png";
const plant02 =
  "https://s3.amazonaws.com/fukushimafish.io/website_assets/about_plant02.png";

const koiImages = () => {
  let arr = [];
  for (let index = 1; index <= 12; index++) {
    arr.push({
      normal: `https://s3.amazonaws.com/fukushimafish.io/website_assets/home_koi${index}.png`,
      low: `https://s3.amazonaws.com/fukushimafish.io/website_assets/home_koi${index}_low.png`,
    });
  }
  return arr;
};

const Home = ({ isMobile, is500, is1440 }) => {
  const [section1Buttons, setSection1Buttons] = useState("none");
  const [selectedKoi, setSelectedKoi] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [isMintPageButton, setIsMintPageButton] = useState(false);
  const [isClickingMintPage, setiIsClickingMintPage] = useState(false);

  const prevScrollY = useRef(0);
  const ref = useRef(null);
  const containerRef = useRef(null);

  const { scrollY } = useScroll();

  const yUp = useTransform(scrollY, [0, 500], ["0vh", "80vh"]);
  const yDown = useTransform(scrollY, [0, 200], ["0vh", "-80vh"]);
  const yDownSection1 = useTransform(scrollY, [0, 650], ["80vh", "0vh"]);
  const yDownSection1_02 = useTransform(
    scrollY,
    [1200, 1750],
    ["0vh", "-80vh"]
  );
  const yDownSection2 = useTransform(scrollY, [650, 1550], ["80vh", "0vh"]);
  const yDownSection2_02 = useTransform(scrollY, [1850, 2250], ["0vh", "60vh"]);
  const yOpacitySection2_02 = useTransform(scrollY, [1950, 2250], ["1", "0"]);
  const yDownSection3 = useTransform(scrollY, [1550, 2150], ["80vh", "0vh"]);
  const yDownSection3_02 = useTransform(scrollY, [1550, 2250], ["80vh", "0vh"]);
  const yDownSection4 = useTransform(scrollY, [2250, 2750], ["40vh", "0vh"]);
  const yKoiAnimation01 = useTransform(
    scrollY,
    [0, 1550, 2150, 2750],
    ["-50vh", "-10vh", "-10vh", "20vh"]
  );

  const onClickSection1Button = (scrollOffset) => {
    ref.current.scrollTo({
      left: ref.current.scrollLeft + scrollOffset,
      behavior: "smooth",
    });
  };

  const scrollToCenter = () => {
    ref.current.scrollTo({
      left: containerRef.current.offsetWidth / 2,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToCenter();
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;

      if (currentScrollY > prevScrollY.current) {
        if (ref.current) {
          if (currentScrollY < 650) {
            ref.current.scrollTo({
              left: ref.current.scrollLeft + 200,
              behavior: "smooth",
            });
          } else if (currentScrollY > 850 && currentScrollY < 1200) {
            ref.current.scrollTo({
              left: ref.current.scrollLeft - 200,
              behavior: "smooth",
            });
          }
        }
      } else if (currentScrollY < prevScrollY.current) {
        if (ref.current) {
          if (currentScrollY < 650) {
            ref.current.scrollTo({
              left: ref.current.scrollLeft - 250,
              behavior: "smooth",
            });
          } else if (currentScrollY > 850 && currentScrollY < 1200) {
            ref.current.scrollTo({
              left: ref.current.scrollLeft + 250,
              behavior: "smooth",
            });
          }
        }
      }

      prevScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    /*const intervalId = setInterval(() => {
      setIsMintPageButton(!isMintPageButton);
    }, 3000);*/

    return () => {
      window.removeEventListener("scroll", handleScroll);
      //clearInterval(intervalId);
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1.5 }}
    >
      {!is500 ? (
        <>
          <motion.img
            style={{ display: isClickingMintPage ? "none" : "auto" }}
            src={mintButton}
            id="home_mint_button"
          />
          <Link
            to="./claim"
            /*to="https://docs.google.com/forms/d/e/1FAIpQLSeEqBVmL0n5U_r1xh4EGnrSeEKx7O4AHFKrAUH45XOdaP9OTg/viewform"
            target="_blank"
            rel="noopener noreferrer"*/
            onClick={() => {
              setiIsClickingMintPage(true);
              document.body.style.backgroundImage =
                "url('https://s3.amazonaws.com/fukushimafish.io/website_assets/mint_background.png')";
            }}
          >
            <motion.div
              animate={
                isClickingMintPage && {
                  y: 500,
                  x: -1400,
                  rotate: -35,
                  scale: 4,
                }
              }
              transition={{ duration: 1.2 }}
              className="home_mint_button_click_container"
            >
              <AnimatePresence>
                <motion.img
                  whileHover={{ opacity: 1 }}
                  animate={{ opacity: 0 }}
                  transition={{
                    ease: "linear",
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse",
                  }}
                  src={button01}
                  id="home_mint_button01"
                />
              </AnimatePresence>

              <motion.img
                /*animate={isClickingMintPage && { opacity: 0, y: 500, x: -1000 }}
                transition={{ duration: 5 }}*/
                src={button02}
                id="home_mint_button02"
              />
              <motion.h3
                style={{
                  color: "#fff",
                  display: isClickingMintPage ? "none" : "auto",
                }}
                id="home_mint_button_text"
              >
                CLAIM
              </motion.h3>
            </motion.div>
          </Link>
        </>
      ) : (
        <>
          <Link
            to="./claim"
            onClick={() => {
              setiIsClickingMintPage(true);
              document.body.style.backgroundImage =
                "url('https://s3.amazonaws.com/fukushimafish.io/website_assets/mint_background_500.png')";
            }}
          >
            <motion.div
              animate={
                isClickingMintPage && {
                  y: -600,
                  x: -300,
                  rotate: -35,
                  scale: 2,
                }
              }
              transition={{ duration: 1.2 }}
              className="home_mint_button_click_container"
            >
              <AnimatePresence>
                <motion.img
                  animate={{ opacity: 0 }}
                  transition={{
                    ease: "linear",
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse",
                  }}
                  src={button01Mobile}
                  id="home_mint_button01"
                />
              </AnimatePresence>

              <motion.img src={button02Mobile} id="home_mint_button02" />
              <div className="mint-button-text-container">
                <svg height="158" width="360" id="home_mint_button_text">
                  <text
                    x="70%"
                    y="35%"
                    fill="#fff"
                    fontFamily="'KoHo'"
                    fontWeight="600"
                  >
                    CLAIM
                  </text>
                </svg>
              </div>
            </motion.div>
          </Link>
          <img src={mintButtonMobile} id="home_mint_button" />
        </>
      )}

      <div className="Home">
        <section className="hero">
          <motion.img
            src={
              is500
                ? heroContainer900
                : isMobile
                ? heroContainer900
                : is1440
                ? heroContainer1440
                : heroContainer
            }
            id="hero_container"
          />
          {!isMobile && (
            <motion.img
              style={{
                y: yUp,
              }}
              src={is1440 ? mountains1440 : mountains}
              id="hero_mountains"
            />
          )}
        </section>
        <motion.section initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 3}} className="section1">
          <img src={waterfall} id="section1_waterfalll" />

          <div className="section1-container">
            <div className="section1-container-wrapper">
              <AnimatePresence>
                <motion.div
                  style={
                    !isMobile && {
                      y: yDownSection1,
                    }
                  }
                  className="section1-box-one"
                >
                  <motion.div
                    key={selectedKoi}
                    initial={{ opacity: 0.7 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 1 }}
                    transition={{ duration: 2 }}
                  >
                    <motion.img
                      src={
                        koiImages().find((i, index) => index === selectedKoi)
                          ?.normal
                      }
                    />
                  </motion.div>
                </motion.div>
              </AnimatePresence>
              <div className="section1-box-two">
                <div className="section1-box-two-container">
                  <div clasName="section1-box-two-container-text">
                    <h2>
                      Meaningful Handcrafted {!isMobile && <br />} Koi in High
                      Resolution
                    </h2>
                    <p>
                      Dive into the breathtaking world of Fukushima Fish - a
                      one-of-a-kind NFT collection featuring 3888 beautifully
                      crafted high resolution radiant koi fish on the Ethereum
                      blockchain.
                    </p>
                  </div>

                  <motion.div
                    onMouseEnter={() => {
                      if (!isMobile) {
                        setSection1Buttons("block");
                      }
                    }}
                    onMouseLeave={() => {
                      if (!isMobile) {
                        setSection1Buttons("none");
                      }
                    }}
                    style={{ display: "flex", position: "relative" }}
                  >
                    <div
                      className="carousel-arrow-left"
                      style={{ display: !isMobile ? section1Buttons : "block" }}
                    >
                      <div className="carousel-arrow-container">
                        <motion.div
                          whileHover={{ scale: 1.06 }}
                          whileTap={{ scale: 1.2 }}
                          onClick={() => onClickSection1Button(-680)}
                        >
                          <ArrowLeft size="25px" />
                        </motion.div>
                      </div>
                    </div>
                    <motion.div ref={ref} className="carousel">
                      <motion.div
                        className="carousel-container"
                        ref={containerRef}
                        /*style={!isMobile && { x: yCarouselAnimation }}*/
                      >
                        {koiImages().map((item, index) => (
                          <motion.div
                            whileHover={{ scale: 1.04 }}
                            whileTap={{ scale: 1.15 }}
                            className="koi-card"
                            key={index}
                            onClick={() => setSelectedKoi(index)}
                          >
                            <motion.img src={item.normal} />
                          </motion.div>
                        ))}
                      </motion.div>
                    </motion.div>
                    <div
                      className="carousel-arrow-right"
                      style={{ display: section1Buttons }}
                    >
                      <div className="carousel-arrow-container">
                        <motion.div
                          whileHover={{ scale: 1.06 }}
                          whileTap={{ scale: 1.2 }}
                          onClick={() => onClickSection1Button(680)}
                        >
                          <ArrowRight size="25px" />
                        </motion.div>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>

            <motion.div
              style={{ y: yDownSection1_02 }}
              className="section1-plant-detail"
            >
              <img src={section1Plant} />
              <img src={section1Mask} />
              <img src={smallBubble} />
            </motion.div>
          </div>
        </motion.section>
        <section className="section2">
          <motion.div
            style={
              !isMobile && {
                y: yDownSection2,
              }
            }
            className="section2-container"
          >
            <div className="section2-container-wrapper">
              <div className="section2-box-one">
                <p>THEY ADAPT, THEY OVERCOME.</p>
                <h2>
                  Symbols of Hope and Persistence. A true celebration of
                  Japanese Heritage
                </h2>
                <p>
                  Crafted with meticulous attention to detail, these pieces of
                  art are made in collaboration with a Japanese Ceremonial Tea
                  Master, and carry a powerful cultural significance.
                </p>
                <p>
                  Each Fukushima Fish NFT may contain kanji, 1 of 35 Mon
                  symbols, and the artist's signature, adding to the
                  collection's enigmatic appeal. Let their beauty be a constant
                  reminder of the strength of the human spirit.
                </p>
              </div>
              <div className="section2-box-two">
                <div className="section2-box-two-wrapper">
                  <img src={smallBubble} />
                  <img src={smallBubble} />
                  <img src={bubble} />
                  <img src={section2Kamon} />
                  <motion.img
                    style={{
                      y: yDownSection2_02,
                      opacity: yOpacitySection2_02,
                    }}
                    src={section2Kanji02}
                  />
                  <motion.img
                    style={{
                      y: yDownSection2_02,
                      opacity: yOpacitySection2_02,
                    }}
                    src={smallBubble}
                  />
                </div>
              </div>
            </div>
            <motion.img
              style={{ y: yDownSection2_02, opacity: yOpacitySection2_02 }}
              src={section2Kanji}
              id="section2_kanji"
            />
          </motion.div>
        </section>
        <section className="section3">
          <div className="section3-container">
            <div className="section3-container-wrapper">
              <motion.div
                style={
                  !isMobile && {
                    y: yDownSection3_02,
                  }
                }
                className="section3-box-one"
              >
                <img src={section3Iphone} />
                <img src={section3Shoes} />
                <img src={section3Skateboard} />
                <img src={section3Tshirt} />
              </motion.div>
              <motion.div
                style={
                  !isMobile && {
                    y: yDownSection3,
                  }
                }
                className="section3-box-two"
              >
                <h2>
                  Dive into the depths of the blockchain and own the IP rights
                  to your Fukushima Fish
                </h2>
                <p>
                  As a proud owner of one of these magnificent pieces, you'll
                  own the intellectual property rights of your unique koi fish,
                  making it an exceptional addition to your art collection. It's
                  the perfect choice for collectors with a keen eye for high
                  quality, and for those who cherish unique and meaningful art.
                </p>
              </motion.div>
            </div>
          </div>
        </section>
        <section className="section4">
          <motion.div
            style={
              !isMobile && {
                y: yKoiAnimation01,
              }
            }
            id="bgKoi"
          >
            <img src={bgKoi} style={{ display: isMobile && "none" }} />
          </motion.div>

          <motion.div
            style={
              !isMobile && {
                y: yDownSection4,
              }
            }
            className="section4-container"
          >
            <div className="section4-container-wrapper">
              <div className="section4-box-one">
                <h2>Our Partners</h2>
                <p>
                  Partnerships of Fukushima Fish allow holders additional{" "}
                  <br style={{ display: isMobile && "none" }} />
                  paths for utilities of their IP and artwork
                </p>
              </div>
              <div className="section4-box-two">
                <div className="partnership-list">
                  <div className="partnership-list-container animate">
                    <a
                      href="https://nftcanvases.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a
                      href="https://www.usecyan.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a className="partnership-card"></a>
                    <a
                      href="https://birthmark-beta.vercel.app/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a className="partnership-card"></a>
                    <a
                      href="https://www.swapstreet.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a
                      href="https://www.protocol.style/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>

                    <a
                      href="https://nftcanvases.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a
                      href="https://www.usecyan.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a className="partnership-card"></a>
                    <a
                      href="https://birthmark-beta.vercel.app/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a className="partnership-card"></a>
                    <a
                      href="https://www.swapstreet.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                    <a
                      href="https://www.protocol.style/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="partnership-card"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
          <Footer />
          <img src={plant01} id="about_plant01" />
          <img src={plant02} id="about_plant02" />
        </section>
      </div>
    </motion.div>
  );
};

export default Home;
